import React, { useState, useRef, useEffect } from 'react';
import { 
  Box, 
  Typography, 
  Grid, 
  Container,
  Paper,
  Chip,
  IconButton,
  Link,
} from '@mui/material';
import {
  GitHub,
  Launch,
  Code,
  Storage,
  Cloud,
  Security,
} from '@mui/icons-material';
import { motion } from 'framer-motion';

export const InteractiveTerminal = ({ height = '400px' }) => {
  const [commands, setCommands] = useState([]);
  const [currentCommand, setCurrentCommand] = useState('');
  const [commandHistory, setCommandHistory] = useState([]);
  const [historyIndex, setHistoryIndex] = useState(-1);
  const inputRef = useRef(null);
  const outputRef = useRef(null);

  const processCommand = (cmd) => {
    const commandMap = {
      'help': `Available Commands:
━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━
  help          Show this help message
  about         Learn about me
  skills        List my technical skills
  projects      View my projects
  experience    View my work experience
  contact       Get my contact information
  clear         Clear the terminal
  github        Open my GitHub profile
  linkedin      Open my LinkedIn profile

Type 'project <number>' or 'experience <company>' for details`,

      'about': `━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━
Hi! I'm Alfredo Perez, a Full Stack Software Engineer based in 
Texas, USA.

I specialize in building scalable web applications and solving 
complex problems. I graduated from TCU with a B.S. in Computer 
Science.

Currently focused on developing innovative solutions at Fractional.
━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━`,

      'skills': `Technical Skills
━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━
Programming Languages:
  • Javascript/Typescript, Python, Java, C/C++

Frameworks/Tools:
  • React/Redux, NodeJS, REST, GraphQL, SQL
  • OpenCV, YOLOv3, Github, AWS, Docker

Languages:
  • English (Fluent)
  • Spanish (Fluent)`,

      'projects': `Recent Projects
━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━
1. Search Engine with Typesense
2. Gas Station Management System
3. Secure Authentication Service

Type 'project <number>' for more details about each project`,

      'experience': `Work Experience
━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━
• Software Engineer at Fractional (Oct 2022 - Present)
• Software Development Intern at Monto (Summer 2021)
• Front-End Developer Intern at Unbent (Spring 2021)

Type 'experience <company>' for more details about each role`,

      'contact': `Let's Connect!
━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━
• Email:    alfredopzr1@gmail.com
• LinkedIn: linkedin.com/in/alfredopzr
• GitHub:   github.com/alfredopzr
• Location: Texas, USA + Remote`,

      'clear': 'CLEAR_TERMINAL',
      
      'github': () => {
        window.open('https://github.com/alfredopzr', '_blank');
        return 'Opening GitHub profile...';
      },
      
      'linkedin': () => {
        window.open('https://linkedin.com/in/alfredopzr', '_blank');
        return 'Opening LinkedIn profile...';
      },
    };

    const args = cmd.toLowerCase().split(' ');
    const command = args[0];
    const param = args[1];

    if (command === 'project' && param) {
      const projectDetails = {
        '1': `Search Engine with Typesense
━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━
Description:
  Fast search implementation with typo tolerance

Key Features:
  • Built with React, TypeScript, and Node.js
  • Real-time search results
  • Improved search performance by 40%
  • Scalable architecture

Tech Stack:
  • Frontend: React, TypeScript
  • Backend: Node.js, Typesense
  • Infrastructure: AWS`,
        '2': `Gas Station Management System:
• Full-stack application for operations management
• Built with React, Node.js, and MongoDB
• Includes inventory control and sales tracking
• Processes over 10,000 transactions daily`,
        '3': `Secure Authentication Service:
• Robust auth system with JWT and OAuth2
• Implements 2FA for enhanced security
• Handles 100K+ user sessions
• 99.9% uptime achievement`,
      };
      return projectDetails[param] || 'Project number not found. Type "projects" to see available projects.';
    }

    if (command === 'experience' && param) {
      const experienceDetails = {
        'fractional': `Fractional (Oct 2022 - Present)
━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━
Role: Software Engineer

Key Achievements:
  • Led end-to-end implementation of critical payment infrastructure 
    using Stripe API, enabling secure bank account connections and 
    payment processing for customers
  • Architected and deployed a new search system using Typesense, 
    significantly improving platform search performance and maintainability
  • Consistently delivered complex features while maintaining and 
    optimizing existing functionality using React, Node.js, GraphQL, 
    and TypeScript
  • Demonstrated strong technical ownership in a startup environment 
    by independently driving multiple high-impact projects
  • Integrated and orchestrated various third-party APIs (Stripe, 
    Fragment, Typesense), including schema design, testing, and deployment`,

        'monto': `Monto (Summer 2021)
━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━
Role: Software Development Intern

Key Achievements:
  • Developed a Proof of Concept integrating an OpenBanking API to 
    extract, aggregate, and visualize user's financial data, 
    streamlining management operations by improving insight on users
  • Collaborated with engineers in development and integration of a 
    Payment-as-a-Service feature used in the existing platform to 
    facilitate bill payments for users`,

        'unbent': `Unbent (Spring 2021)
━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━
Role: Front-End Developer Intern

Key Achievements:
  • Independently developed the initial website from concept to 
    deployment for an early-stage startup through the TCU 
    Entrepreneurial Intern Scholarship Program
  • Utilized JavaScript and Bootstrap to implement a responsive 
    design and the core functionality, handling all aspects of 
    development`,
      };
      return experienceDetails[param] || 'Company not found. Type "experience" to see available companies.';
    }

    const response = commandMap[command];
    return typeof response === 'function' ? response() : response || `Command not found: ${cmd}. Type "help" for available commands.`;
  };

  const initialMessage = [
    { type: 'output', text: 'Welcome! Type "help" to see available commands.' },
    { type: 'input', text: 'help' },
    { type: 'output', text: processCommand('help') },
  ];

  useEffect(() => {
    setCommands(initialMessage);
  }, []);

  useEffect(() => {
    if (outputRef.current) {
      outputRef.current.scrollTop = outputRef.current.scrollHeight;
    }
  }, [commands]);

  const handleCommand = (e) => {
    if (e.key === 'Enter' && currentCommand.trim()) {
      const newCommand = currentCommand.trim();
      const response = processCommand(newCommand);
      
      if (response === 'CLEAR_TERMINAL') {
        setCommands(initialMessage);
      } else {
        const newCommands = [
          ...commands,
          { type: 'input', text: newCommand },
          { type: 'output', text: response }
        ];
        setCommands(newCommands);
        setCommandHistory([...commandHistory, newCommand]);
      }
      
      setCurrentCommand('');
      setHistoryIndex(-1);
    } else if (e.key === 'ArrowUp') {
      e.preventDefault();
      if (historyIndex < commandHistory.length - 1) {
        const newIndex = historyIndex + 1;
        setHistoryIndex(newIndex);
        setCurrentCommand(commandHistory[commandHistory.length - 1 - newIndex]);
      }
    } else if (e.key === 'ArrowDown') {
      e.preventDefault();
      if (historyIndex > 0) {
        const newIndex = historyIndex - 1;
        setHistoryIndex(newIndex);
        setCurrentCommand(commandHistory[commandHistory.length - 1 - newIndex]);
      } else {
        setHistoryIndex(-1);
        setCurrentCommand('');
      }
    }
  };


  return (
    <Paper
      elevation={0}
      sx={{
        p: 3,
        bgcolor: '#1e1e1e',
        borderRadius: 2,
        fontFamily: 'monospace',
        color: '#fff',
        height: height,
        width: '100%',
        position: 'relative',
        overflow: 'hidden',
        display: 'flex',
        flexDirection: 'column',
      }}
    >
      <Box sx={{ 
        position: 'absolute', 
        top: 10, 
        left: 10, 
        display: 'flex', 
        gap: 1,
        zIndex: 2,
        bgcolor: '#1e1e1e',
        py: 1,
        width: '100%',
      }}>
        <Box sx={{ width: 12, height: 12, borderRadius: '50%', bgcolor: '#ff5f56' }} />
        <Box sx={{ width: 12, height: 12, borderRadius: '50%', bgcolor: '#ffbd2e' }} />
        <Box sx={{ width: 12, height: 12, borderRadius: '50%', bgcolor: '#27c93f' }} />
      </Box>
      
      <Box 
        ref={outputRef}
        sx={{ 
          mt: 4,
          px: 2,
          flex: 1,
          overflow: 'auto',
          scrollBehavior: 'smooth',
          '&::-webkit-scrollbar': {
            width: '8px',
          },
          '&::-webkit-scrollbar-track': {
            background: 'rgba(255,255,255,0.1)',
            borderRadius: '4px',
          },
          '&::-webkit-scrollbar-thumb': {
            background: 'rgba(255,255,255,0.2)',
            borderRadius: '4px',
            '&:hover': {
              background: 'rgba(255,255,255,0.3)',
            },
          },
        }}
      >
        {commands.map((cmd, index) => (
          <Box key={index} sx={{ mb: 1 }}>
            {cmd.type === 'input' ? (
              <Typography sx={{ color: '#64ffda' }}>
                $ {cmd.text}
              </Typography>
            ) : (
              <Typography 
                sx={{ 
                  color: '#fff',
                  whiteSpace: 'pre-wrap',
                  fontFamily: 'monospace',
                  fontSize: '0.875rem',
                  lineHeight: '1.5',
                }}
              >
                {cmd.text}
              </Typography>
            )}
          </Box>
        ))}
      </Box>

      <Box 
        sx={{ 
          display: 'flex', 
          alignItems: 'center', 
          gap: 1,
          px: 2,
          py: 2,
          borderTop: '1px solid rgba(255,255,255,0.1)',
        }}
      >
        <Typography sx={{ color: '#64ffda' }}>$</Typography>
        <input
          ref={inputRef}
          value={currentCommand}
          onChange={(e) => setCurrentCommand(e.target.value)}
          onKeyDown={handleCommand}
          style={{
            background: 'transparent',
            border: 'none',
            color: '#fff',
            outline: 'none',
            fontFamily: 'inherit',
            fontSize: 'inherit',
            width: '100%',
          }}
          autoFocus
        />
      </Box>
    </Paper>
  );
};

const Projects = () => {
  const containerVariants = {
    hidden: { opacity: 0 },
    visible: {
      opacity: 1,
      transition: {
        staggerChildren: 0.1,
      },
    },
  };

  const itemVariants = {
    hidden: { y: 20, opacity: 0 },
    visible: {
      y: 0,
      opacity: 1,
    },
  };

  return (
    <Container>
      <Box component={motion.div} variants={containerVariants} initial="hidden" animate="visible">
        <motion.div variants={itemVariants}>
          <Typography 
            variant="overline" 
            sx={{ 
              color: 'primary.main',
              letterSpacing: 2,
              mb: 2,
              display: 'block'
            }}
          >
            FEATURED WORK
          </Typography>
        </motion.div>

        <motion.div variants={itemVariants}>
          <Typography variant="h3" gutterBottom sx={{ fontWeight: 700 }}>
            Projects
          </Typography>
        </motion.div>

        <Grid container spacing={3} sx={{ mt: 4 }}>
          {[
            {
              title: "Search Engine with Typesense",
              description: "Fast search implementation with typo tolerance and real-time results. Improved search performance by 40% using optimized indexing.",
              tech: ["React", "TypeScript", "Node.js", "AWS"],
              links: {
                github: "https://github.com/alfredopzr",
              },
              icon: <Code />
            },
            {
              title: "Gas Station Management System",
              description: "Full-stack application for operations management, handling 10,000+ daily transactions with inventory control and sales tracking.",
              tech: ["React", "Node.js", "MongoDB", "Docker"],
              links: {
                github: "https://github.com/alfredopzr"
              },
              icon: <Storage />
            },
            {
              title: "Secure Authentication Service",
              description: "Robust authentication system with JWT, OAuth2, and 2FA implementation. Handles 100K+ user sessions with 99.9% uptime.",
              tech: ["Node.js", "PostgreSQL", "Redis", "AWS"],
              links: {
                github: "https://github.com/alfredopzr"
              },
              icon: <Security />
            }
          ].map((project, index) => (
            <Grid item xs={12} md={6} lg={4} key={index}>
              <motion.div variants={itemVariants}>
                <Paper
                  sx={{
                    p: 3,
                    height: '100%',
                    display: 'flex',
                    flexDirection: 'column',
                    position: 'relative',
                    overflow: 'hidden',
                    '&:hover': {
                      '& .project-links': {
                        transform: 'translateY(0)',
                        opacity: 1
                      }
                    }
                  }}
                >
                  <Box sx={{ mb: 2, color: 'primary.main' }}>
                    {project.icon}
                  </Box>
                  <Typography variant="h6" gutterBottom>
                    {project.title}
                  </Typography>
                  <Typography variant="body2" color="text.secondary" sx={{ mb: 2, flex: 1 }}>
                    {project.description}
                  </Typography>
                  <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 1, mb: 2 }}>
                    {project.tech.map((tech, i) => (
                      <Chip key={i} label={tech} size="small" />
                    ))}
                  </Box>
                  <Box
                    className="project-links"
                    sx={{
                      display: 'flex',
                      gap: 1,
                      transition: 'all 0.3s ease',
                      opacity: 0.7,
                      '&:hover': { opacity: 1 }
                    }}
                  >
                    {project.links.github && (
                      <IconButton
                        size="small"
                        component={Link}
                        href={project.links.github}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <GitHub fontSize="small" />
                      </IconButton>
                    )}
                    {project.links.live && (
                      <IconButton
                        size="small"
                        component={Link}
                        href={project.links.live}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <Launch fontSize="small" />
                      </IconButton>
                    )}
                  </Box>
                </Paper>
              </motion.div>
            </Grid>
          ))}
        </Grid>
      </Box>
    </Container>
  );
};
export default Projects;

