import React from 'react';
import { 
  Box, 
  Typography, 
  Container,
  Paper,
  IconButton,
  Grid,
  Divider,
} from '@mui/material';
import {
  Work,
  Code,
  Storage,
  Cloud,
  Build,
  ArrowForward,
} from '@mui/icons-material';
import { motion } from 'framer-motion';

const Professional = () => {
  const containerVariants = {
    hidden: { opacity: 0 },
    visible: {
      opacity: 1,
      transition: {
        staggerChildren: 0.1,
      },
    },
  };

  const itemVariants = {
    hidden: { y: 20, opacity: 0 },
    visible: {
      y: 0,
      opacity: 1,
    },
  };

  const experiences = [
    {
      title: 'Software Engineer',
      company: 'Fractional',
      period: 'Oct 2022 - Present',
      description: 'Led end-to-end implementation of critical payment infrastructure and search functionality.',
      icon: <Cloud />,
      achievements: [
        'Led end-to-end implementation of critical payment infrastructure using Stripe API, enabling secure bank account connections and payment processing for customers',
        'Architected and deployed a new search system using Typesense, significantly improving platform search performance and maintainability',
        'Consistently delivered complex features while maintaining and optimizing existing functionality using React, Node.js, GraphQL, and TypeScript',
        'Demonstrated strong technical ownership in a startup environment by independently driving multiple high-impact projects',
        'Integrated and orchestrated various third-party APIs (Stripe, Fragment, Typesense), including schema design, testing, and deployment'
      ],
      techStack: ['React', 'Node.js', 'GraphQL', 'TypeScript', 'AWS'],
    },
    {
      title: 'Software Development Intern',
      company: 'Monto',
      period: 'Summer 2021',
      description: 'Developed and integrated OpenBanking API solutions.',
      icon: <Build />,
      achievements: [
        'Developed a Proof of Concept integrating an OpenBanking API to extract, aggregate, and visualize user\'s financial data, streamlining management operations by improving insight on users',
        'Collaborated with engineers in development and integration of a Payment-as-a-Service feature used in the existing platform to facilitate bill payments for users'
      ],
      techStack: ['Python', 'Django', 'PostgreSQL', 'Docker'],
    },
    {
      title: 'Front-End Developer Intern',
      company: 'Unbent',
      period: 'Spring 2021',
      description: 'Led initial website development for early-stage startup.',
      icon: <Code />,
      achievements: [
        'Independently developed the initial website from concept to deployment for an early-stage startup through the TCU Entrepreneurial Intern Scholarship Program',
        'Utilized JavaScript and Bootstrap to implement a responsive design and the core functionality, handling all aspects of development'
      ],
      techStack: ['JavaScript', 'Bootstrap', 'HTML/CSS'],
    },
  ];

  return (
    <Container>
      <Box component={motion.div} variants={containerVariants} initial="hidden" animate="visible">
        <motion.div variants={itemVariants}>
          <Typography 
            variant="overline" 
            sx={{ 
              color: 'primary.main',
              letterSpacing: 2,
              mb: 2,
              display: 'block'
            }}
          >
            WORK EXPERIENCE
          </Typography>
        </motion.div>

        <motion.div variants={itemVariants}>
          <Typography variant="h3" gutterBottom sx={{ fontWeight: 700 }}>
            Professional Journey
          </Typography>
        </motion.div>

        <motion.div variants={itemVariants}>
          <Typography variant="body1" paragraph sx={{ mb: 6 }}>
            A track record of delivering impactful solutions and driving technical innovation.
          </Typography>
        </motion.div>

        <Grid container spacing={3}>
          {experiences.map((experience, index) => (
            <Grid item xs={12} key={experience.title}>
              <Paper
                component={motion.div}
                variants={itemVariants}
                elevation={0}
                sx={{
                  p: 4,
                  border: '1px solid',
                  borderColor: 'divider',
                  borderRadius: 2,
                  transition: 'transform 0.2s ease-in-out',
                  '&:hover': {
                    transform: 'translateY(-4px)',
                  },
                }}
              >
                <Box sx={{ display: 'flex', alignItems: 'flex-start', gap: 2, mb: 3 }}>
                  <IconButton
                    sx={{
                      bgcolor: 'primary.main',
                      color: 'white',
                      '&:hover': { bgcolor: 'primary.dark' },
                    }}
                  >
                    {experience.icon}
                  </IconButton>
                  <Box sx={{ flex: 1 }}>
                    <Typography variant="h5" gutterBottom>
                      {experience.title}
                    </Typography>
                    <Typography variant="subtitle1" color="primary" gutterBottom>
                      {experience.company}
                    </Typography>
                    <Typography variant="subtitle2" color="text.secondary">
                      {experience.period}
                    </Typography>
                  </Box>
                </Box>

                <Typography variant="body1" paragraph sx={{ mb: 3 }}>
                  {experience.description}
                </Typography>

                <Box sx={{ mb: 3 }}>
                  {experience.achievements.map((achievement) => (
                    <Box 
                      key={achievement} 
                      sx={{ 
                        display: 'flex', 
                        alignItems: 'flex-start', 
                        gap: 1.5,
                        mb: 2,
                        position: 'relative',
                        pl: 2,
                        '&::before': {
                          content: '""',
                          position: 'absolute',
                          left: 0,
                          top: '0.5em',
                          width: 4,
                          height: 4,
                          bgcolor: 'primary.main',
                          borderRadius: '50%',
                        },
                        '&:hover': {
                          '&::before': {
                            transform: 'scale(1.5)',
                            transition: 'transform 0.2s ease-in-out',
                          },
                        },
                      }}
                    >
                      <Typography 
                        variant="body2"
                        sx={{
                          color: 'text.primary',
                          lineHeight: 1.6,
                        }}
                      >
                        {achievement}
                      </Typography>
                    </Box>
                  ))}
                </Box>

                <Divider sx={{ my: 2 }} />

                <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 1 }}>
                  {experience.techStack.map((tech) => (
                    <Box
                      key={tech}
                      sx={{
                        px: 1.5,
                        py: 0.5,
                        borderRadius: 1,
                        bgcolor: 'background.default',
                        border: '1px solid',
                        borderColor: 'divider',
                        display: 'flex',
                        alignItems: 'center',
                        gap: 0.5,
                      }}
                    >
                      <Code sx={{ fontSize: 16, color: 'primary.main' }} />
                      <Typography variant="body2">
                        {tech}
                      </Typography>
                    </Box>
                  ))}
                </Box>
              </Paper>
            </Grid>
          ))}
        </Grid>
      </Box>
    </Container>
  );
};

export default Professional;

