import React from 'react';
import { Box, Container, useTheme, useMediaQuery } from '@mui/material';
import { motion } from 'framer-motion';
import Home from './Home';
import Personal from './Personal';
import Professional from './Professional';
import Projects from './Projects';
import Terminal from './Terminal';

const ContentArea = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const sectionStyles = {
    minHeight: { xs: 'auto', md: '100vh' },
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    py: { xs: 6, md: 8 },
    px: { xs: 2, md: 3 },
    boxSizing: 'border-box',
    scrollMarginTop: `${theme.mixins.toolbar.minHeight}px`,
  };

  const containerStyles = {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    width: '100%',
    gap: { xs: 4, md: 6 },
  };

  return (
    <Box 
      component="main" 
      sx={{
        display: 'flex',
        flexDirection: 'column',
        minHeight: '100vh',
        width: '100%',
        pt: `${theme.mixins.toolbar.minHeight}px`,
      }}
    >
      <motion.div
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ duration: 0.5 }}
        style={{ width: '100%' }}
      >
        <Box id="home" sx={{ ...sectionStyles, bgcolor: 'background.default' }}>
          <Container maxWidth="lg" sx={containerStyles}>
            <Home />
          </Container>
        </Box>

        <Box id="personal" sx={{ ...sectionStyles, bgcolor: 'background.paper' }}>
          <Container maxWidth="lg" sx={containerStyles}>
            <Personal />
          </Container>
        </Box>
        <Box id="professional" sx={{ ...sectionStyles, bgcolor: 'background.default' }}>
          <Container maxWidth="lg" sx={containerStyles}>
            <Professional />
          </Container>
        </Box>
      </motion.div>
    </Box>
  );
};

export default ContentArea;
