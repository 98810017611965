import React from 'react';
import { Box, Container, IconButton, Typography } from '@mui/material';
import { GitHub, LinkedIn, Twitter, Email } from '@mui/icons-material';

const Footer = () => {
  return (
    <Box
      component="footer"
      sx={{
        py: 3,
        px: 2,
        mt: 'auto',
        backgroundColor: 'background.paper',
        borderTop: '1px solid',
        borderColor: 'divider',
      }}
    >
      <Container maxWidth="lg">
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            flexDirection: 'column',
            gap: 2,
          }}
        >
          <Box sx={{ display: 'flex', gap: 2 }}>
            <IconButton color="inherit" href="https://github.com/alfredopzr" target="_blank">
              <GitHub />
            </IconButton>
            <IconButton color="inherit" href="https://www.linkedin.com/in/alfredopzr" target="_blank">
              <LinkedIn />
            </IconButton>
            <IconButton color="inherit" href="https://twitter.com/alfredopzr" target="_blank">
              <Twitter />
            </IconButton>
            <IconButton color="inherit" href="mailto:alfredopzr1@gmail.com">
              <Email />
            </IconButton>
          </Box>
          <Typography variant="body2" color="text.secondary" align="center">
            © {new Date().getFullYear()} Alfredo Perez. All rights reserved.
          </Typography>
        </Box>
      </Container>
    </Box>
  );
};

export default Footer;
