import React, { useState } from 'react';
import { 
  Box, 
  Typography, 
  Grid, 
  Container,
  Paper,
  IconButton,
  Button,
  Tooltip,
  Chip,
} from '@mui/material';
import {
  GitHub,
  LinkedIn,
  Language,
  LocationOn,
  School,
  Terminal,
  KeyboardReturn,
} from '@mui/icons-material';
import { motion, AnimatePresence } from 'framer-motion';
import { InteractiveTerminal } from './Projects';

const Personal = () => {
  const [showTerminal, setShowTerminal] = useState(false);
  
  const containerVariants = {
    hidden: { opacity: 0 },
    visible: {
      opacity: 1,
      transition: {
        staggerChildren: 0.1,
      },
    },
  };

  const itemVariants = {
    hidden: { y: 20, opacity: 0 },
    visible: {
      y: 0,
      opacity: 1,
    },
  };

  const quickInfo = [
    { icon: <LocationOn />, text: 'Texas, USA + Remote' },
    { icon: <Language />, text: 'English, Spanish' },
    { icon: <School />, text: 'B.S. Computer Science, TCU' },
    { icon: <GitHub />, link: 'github.com/alfredopzr' },
    { icon: <LinkedIn />, link: 'linkedin.com/in/alfredopzr' },
  ];

  const skills = {
    frontend: ['React', 'Next.js', 'TypeScript', 'GraphQL'],
    backend: ['Node.js', 'Python', 'Java', 'C/C++'],
    dev_tools: ['Git', 'Docker', 'AWS', 'SQL'],
    interests: ['AI/ML', 'Data Science', 'Blockchain/DeFi'],
  };

  return (
    <Container>
      <Box 
        component={motion.div} 
        variants={containerVariants} 
        initial="hidden" 
        animate="visible"
        sx={{ 
          minHeight: { xs: 'auto', md: '85vh' },
          display: 'flex',
          flexDirection: 'column',
          py: { xs: 4, md: 0 },
        }}
      >
        <motion.div variants={itemVariants}>
          <Typography 
            variant="overline" 
            sx={{ 
              color: 'primary.main',
              letterSpacing: 2,
              mb: 2,
              display: 'block'
            }}
          >
            ABOUT ME/CLI
          </Typography>
        </motion.div>

        <AnimatePresence mode="wait">
          {showTerminal ? (
            <motion.div
              key="terminal"
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              exit={{ opacity: 0 }}
              transition={{ duration: 0.3 }}
              style={{ flex: 1 }}
            >
              <Box sx={{ 
                position: 'relative', 
                height: { xs: '400px', md: '500px' }
              }}>
                <InteractiveTerminal height="100%" />
              </Box>
            </motion.div>
          ) : (
            <motion.div
              key="content"
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              exit={{ opacity: 0 }}
              transition={{ duration: 0.3 }}
            >
              <Grid container spacing={{ xs: 2, md: 3 }}>
                <Grid item xs={12}>
                  <Paper
                    component={motion.div}
                    variants={itemVariants}
                    elevation={0}
                    sx={{
                      p: { xs: 2.5, md: 5 },
                      borderRadius: 2,
                      background: 'linear-gradient(145deg, #2196f3 0%, #1976d2 100%)',
                      color: 'white',
                      position: 'relative',
                      minHeight: { xs: 'auto', md: '500px' },
                    }}
                  >
                    <Box sx={{ 
                      display: 'flex', 
                      flexDirection: { xs: 'column', md: 'row' },
                      gap: { xs: 3, md: 6 },
                    }}>
                      <Box sx={{ flex: 1 }}>
                        <Typography variant="h4" gutterBottom sx={{ fontWeight: 700 }}>
                          Hello, I'm Alfredo
                        </Typography>
                        <Typography variant="body1" sx={{ mb: 3, fontSize: '1.1rem', lineHeight: 1.6 }}>
                          I'm a software engineer with a Computer Science degree from Texas Christian University. 
                          My passion lies in building innovative solutions and turning complex problems into elegant code. 
                          With a strong foundation in both theory and practice, I specialize in developing scalable 
                          applications that make a real impact.
                        </Typography>
                        <Box sx={{ 
                          display: 'flex',
                          flexWrap: 'wrap',
                          gap: { xs: 1, md: 1.5 },
                          mb: { xs: 2, md: 3 },
                          justifyContent: { xs: 'center', md: 'flex-start' },
                        }}>
                          {quickInfo.map((info, index) => (
                            <Box
                              key={index}
                              sx={{
                                display: 'inline-flex',
                                alignItems: 'center',
                                gap: 1,
                                color: 'white',
                                bgcolor: 'rgba(255,255,255,0.1)',
                                borderRadius: 1,
                                px: 1.5,
                                py: 0.5,
                                minWidth: 'fit-content',
                                fontSize: { xs: '0.75rem', md: '0.875rem' },
                                '& .MuiSvgIcon-root': {
                                  fontSize: '1rem',
                                },
                              }}
                            >
                              {info.icon}
                              <Typography 
                                variant="body2" 
                                sx={{ 
                                  fontSize: 'inherit',
                                  lineHeight: 1,
                                }}
                              >
                                {info.text || info.link}
                              </Typography>
                            </Box>
                          ))}
                        </Box>
                      </Box>

                      <Box sx={{ 
                        display: 'grid',
                        gridTemplateColumns: { 
                          xs: 'repeat(2, 1fr)', 
                          sm: 'repeat(2, 1fr)',
                          md: 'repeat(1, 1fr)' 
                        },
                        gap: 2,
                      }}>
                        {Object.entries(skills).map(([category, items]) => (
                          <Box key={category}>
                            <Typography 
                              variant="overline" 
                              sx={{ 
                                color: 'rgba(255,255,255,0.7)',
                                display: 'block',
                                mb: 1,
                              }}
                            >
                              {category}
                            </Typography>
                            <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 1 }}>
                              {items.map((item) => (
                                <Chip
                                  key={item}
                                  label={item}
                                  size="small"
                                  sx={{
                                    bgcolor: 'rgba(255,255,255,0.1)',
                                    color: 'white',
                                    '&:hover': {
                                      bgcolor: 'rgba(255,255,255,0.2)',
                                    },
                                  }}
                                />
                              ))}
                            </Box>
                          </Box>
                        ))}
                      </Box>
                    </Box>
                    <Box sx={{ 
                      position: { xs: 'relative', md: 'absolute' },
                      bottom: { md: 20 },
                      right: { md: 20 },
                      mt: { xs: 3, md: 0 },
                      display: 'flex',
                      justifyContent: { xs: 'center', md: 'flex-end' },
                    }}>
                      <Tooltip title="Try the interactive CLI!" placement="left" arrow>
                        <IconButton
                          onClick={() => setShowTerminal(true)}
                          sx={{
                            bgcolor: 'rgba(255,255,255,0.1)',
                            backdropFilter: 'blur(4px)',
                            color: 'white',
                            '&:hover': {
                              bgcolor: 'rgba(255,255,255,0.2)',
                              transform: 'scale(1.1)',
                            },
                            animation: 'pulse 2s infinite',
                          }}
                        >
                          <Terminal />
                        </IconButton>
                      </Tooltip>
                      <Typography
                        variant="caption"
                        sx={{
                          color: 'rgba(255,255,255,0.8)',
                          textAlign: 'center',
                          animation: 'bounce 2s infinite',
                        }}
                      >
                        Try CLI
                      </Typography>
                    </Box>
                  </Paper>
                </Grid>
              </Grid>
            </motion.div>
          )}
        </AnimatePresence>
      </Box>

      <style jsx global>{`
        @keyframes pulse {
          0% { box-shadow: 0 0 0 0 rgba(255,255,255,0.4); }
          70% { box-shadow: 0 0 0 10px rgba(255,255,255,0); }
          100% { box-shadow: 0 0 0 0 rgba(255,255,255,0); }
        }
        @keyframes bounce {
          0%, 20%, 50%, 80%, 100% { transform: translateY(0); }
          40% { transform: translateY(-3px); }
          60% { transform: translateY(-1.5px); }
        }
      `}</style>
    </Container>
  );
};

export default Personal;
