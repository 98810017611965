import React, { useState } from 'react';
import { 
  Box, 
  Typography, 
  Chip, 
  Grid, 
  Container,
  useTheme,
  useMediaQuery,
  IconButton,
} from '@mui/material';
import { 
  Code, 
  Terminal,
  GitHub,
  LinkedIn,
  ArrowDownward,
} from '@mui/icons-material';
import { motion } from 'framer-motion';

const Home = () => {
  const theme = useTheme();
  
  const technologies = [
    'TypeScript', 'React', 'Node.js', 'Python', 'AWS', 'Docker'
  ];

  const containerVariants = {
    hidden: { opacity: 0 },
    visible: {
      opacity: 1,
      transition: {
        staggerChildren: 0.1,
      },
    },
  };

  const itemVariants = {
    hidden: { y: 20, opacity: 0 },
    visible: {
      y: 0,
      opacity: 1,
    },
  };

  return (
    <Container>
      <Grid container spacing={4} alignItems="center" sx={{ minHeight: '90vh' }}>
        <Grid item xs={12} md={7}>
          <Box component={motion.div} variants={containerVariants} initial="hidden" animate="visible">
            <motion.div variants={itemVariants}>
              <Typography 
                variant="overline" 
                sx={{ 
                  color: 'primary.main',
                  letterSpacing: 2,
                  mb: 2,
                  display: 'block'
                }}
              >
                SOFTWARE ENGINEER
              </Typography>
            </motion.div>

            <motion.div variants={itemVariants}>
              <Typography 
                variant="h2" 
                component="h1" 
                sx={{ 
                  fontWeight: 700,
                  mb: 2,
                  background: `linear-gradient(45deg, ${theme.palette.primary.main}, ${theme.palette.primary.dark})`,
                  WebkitBackgroundClip: 'text',
                  WebkitTextFillColor: 'transparent',
                }}
              >
                Alfredo Perez
              </Typography>
            </motion.div>

            <motion.div variants={itemVariants}>
              <Typography 
                variant="h5" 
                color="text.secondary" 
                sx={{ mb: 4, fontWeight: 300 }}
              >
                Building scalable solutions and turning complex problems into elegant code.
              </Typography>
            </motion.div>

            <Box component={motion.div} variants={itemVariants} sx={{ mb: 4 }}>
              <Box sx={{ display: 'flex', gap: 1, flexWrap: 'wrap' }}>
                {technologies.map((tech) => (
                  <Chip
                    key={tech}
                    label={tech}
                    icon={<Code />}
                    variant="outlined"
                    sx={{
                      borderColor: 'primary.main',
                      '&:hover': {
                        backgroundColor: 'primary.main',
                        color: 'white',
                        '& .MuiSvgIcon-root': {
                          color: 'white',
                        },
                      },
                    }}
                  />
                ))}
              </Box>
            </Box>

            <Box 
              component={motion.div} 
              variants={itemVariants}
              sx={{ 
                display: 'flex',
                gap: 2,
                mb: 6
              }}
            >
              <IconButton 
                href="https://github.com/alfredopzr" 
                target="_blank"
                sx={{ 
                  border: '2px solid',
                  borderColor: 'primary.main',
                  '&:hover': {
                    backgroundColor: 'primary.main',
                    color: 'white',
                  },
                }}
              >
                <GitHub />
              </IconButton>
              <IconButton 
                href="https://linkedin.com/in/alfredopzr" 
                target="_blank"
                sx={{ 
                  border: '2px solid',
                  borderColor: 'primary.main',
                  '&:hover': {
                    backgroundColor: 'primary.main',
                    color: 'white',
                  },
                }}
              >
                <LinkedIn />
              </IconButton>
            </Box>
          </Box>
        </Grid>

        <Grid item xs={12} md={5} sx={{ display: { xs: 'none', md: 'block' } }}>
          
            <Box
              component={motion.div}
              initial={{ opacity: 0, scale: 0.5 }}
              animate={{ opacity: 1, scale: 1 }}
              transition={{ duration: 0.5 }}
              sx={{
                bgcolor: 'background.paper',
                p: 3,
                borderRadius: 2,
                boxShadow: 3,
                fontFamily: 'monospace',
                position: 'relative',
                height: '300px',
              }}
            >
              <Box sx={{ 
                position: 'absolute', 
                top: 10, 
                left: 10, 
                display: 'flex', 
                gap: 1,
                width: '100%',
                justifyContent: 'space-between',
                pr: 4,
                zIndex: 2,
              }}>
                <Box sx={{ display: 'flex', gap: 1 }}>
                  <Box sx={{ width: 12, height: 12, borderRadius: '50%', bgcolor: '#ff5f56' }} />
                  <Box sx={{ width: 12, height: 12, borderRadius: '50%', bgcolor: '#ffbd2e' }} />
                  <Box sx={{ width: 12, height: 12, borderRadius: '50%', bgcolor: '#27c93f' }} />
                </Box>
              </Box>

              <Box sx={{ mt: 3, height: 'calc(100% - 40px)', overflow: 'hidden' }}>
                <Typography
                  variant="body2"
                  sx={{
                    color: 'success.main',
                    whiteSpace: 'pre-wrap',
                  }}
                >
                  {`const developer = {
  name: 'Alfredo Perez',
  type: 'Full Stack Engineer',
  location: 'World Wide Web',
  interests: [
    'System Design + Software Engineering',
    'Web Development',
    'DeFi',
  ],
  currentlyLearning: 'AI/ML'
};`}
                </Typography>
              </Box>
            </Box>
          
        </Grid>
      </Grid>

      <Box 
        component={motion.div}
        initial={{ opacity: 0, y: 20 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ delay: 1 }}
        sx={{ 
          position: 'absolute',
          bottom: 40,
          left: '50%',
          transform: 'translateX(-50%)',
          textAlign: 'center',
        }}
      >
        <Typography variant="body2" color="text.secondary" sx={{ mb: 1 }}>
          Scroll to explore
        </Typography>
        <IconButton 
          color="primary"
          sx={{ animation: 'bounce 2s infinite' }}
        >
          <ArrowDownward />
        </IconButton>
      </Box>

      <style jsx global>{`
        @keyframes bounce {
          0%, 20%, 50%, 80%, 100% {
            transform: translateY(0);
          }
          40% {
            transform: translateY(-10px);
          }
          60% {
            transform: translateY(-5px);
          }
        }
      `}</style>
    </Container>
  );
};

export default Home;
